import { Divider, Input, notification, Space, Tabs } from 'antd';
import React, { createRef, Fragment, useEffect, useState } from 'react';
import ic_recent from '../../assets/images/ic_recently.png';
import ic_recent_active from '../../assets/images/ic_recently_active.png';
import ic_favorite from '../../assets/images/ic_favorite.png';
import ic_favorite_active from '../../assets/images/ic_favorite_active.png';
import ic_all from '../../assets/images/ic_all.png';
import ic_all_active from '../../assets/images/ic_all_active.png';
import { useMediaQuery } from 'react-responsive';
import { SearchOutlined } from '@ant-design/icons';
import Tab from './components/Tab';
import MenuItem from './components/MenuItem';
import { Menu as MenuApi } from 'api';
import _ from 'lodash';
import useDebounce from 'hook/useDebounce';
import { AppDispatch, useAppSelector } from 'redux/store';
import { useDispatch } from 'react-redux';
import { setListMenu, setListMenuRecent } from 'redux/slices/menuSlice';

const { TabPane } = Tabs;

const listTabInnit = [
	{ id: 1, name: 'Vừa truy cập', icon: ic_recent, iconActive: ic_recent_active, list: [] },
	{ id: 2, name: 'Yêu thích', icon: ic_favorite, iconActive: ic_favorite_active, list: [] },
	{ id: 3, name: 'Tất cả', icon: ic_all, iconActive: ic_all_active, list: [] },
];

const MenuDashboard: React.FC<{
	isShow: boolean;
	isShowMenu: boolean;
	isShowSearch: boolean;
	inputSearch: string;
	onChange: Function;
	closeMenu: Function;
}> = ({ isShow, inputSearch, onChange, closeMenu, isShowMenu, isShowSearch }) => {
	const [active, setActive] = useState('3');
	const [listTab, setListTab] = useState(listTabInnit);
	const [listCategory, setListCategory] = useState([]);
	const [dataSearch, setDataSearch] = useState([]);

	const debouncedSearch = useDebounce(inputSearch, 500);
	const { userInfo } = useAppSelector((state) => state.user);
	const { listMenu, listFavoriteMenu, listRecentMenu } = useAppSelector((state) => state.menu);
	const refMenu = createRef<HTMLDivElement>();
	const dispatch = useDispatch<AppDispatch>();

	const isMobile = useMediaQuery({
		query: '(max-width: 767px)',
	});

	const onChangeTab = (activeKey) => {
		if (refMenu && refMenu.current) {
			refMenu.current.scrollTo(0, 0);
		}
		setActive(activeKey);
	};

	const handleOnUpdateFavorite = async (itemMenu) => {
		try {
			const { data } = await MenuApi.updateFavorite({
				...itemMenu,
				isFavourite: !itemMenu.isFavourite,
			});

			const newListMenu = listMenu.map((item) => {
				if (item.pageId === itemMenu.pageId) {
					return { ...itemMenu, isFavourite: !itemMenu.isFavourite };
				}
				return item;
			});
			dispatch(setListMenu(newListMenu));
			dispatch(
				setListMenuRecent(
					listRecentMenu.map((item) => {
						if (item.pageId === itemMenu.pageId) {
							return { ...itemMenu, isFavourite: !itemMenu.isFavourite };
						}
						return item;
					})
				)
			);

			if (data.errorCode) {
				notification.error({
					message: 'Thông báo',
					description: data.message,
				});
			} else {
				notification.success({
					key: 'favourite',
					message: 'Thông báo',
					description: itemMenu.isFavourite
						? 'Xoá menu yêu thích thành công!'
						: 'Thêm menu yêu thích thành công!',
					duration: 1.5,
				});
			}
		} catch (error) {}
	};

	const getListMenu = async (params?) => {
		try {
			const { data } = await MenuApi.getListMenu(params);
			if (data.data.length > 0) {
				return data.data;
			}
		} catch (error) {}
		return [];
	};

	const getMenu = async () => {
		try {
			const dataAllMenu = await getListMenu();
			const dataRecentlyMenu = await getListMenu({ IsLastAccess: true, PageSize: 12 });
			dispatch(setListMenu(dataAllMenu));
			dispatch(setListMenuRecent(dataRecentlyMenu));
			setDataSearch(dataAllMenu);
		} catch (error) {}
	};

	const getDataSearch = async () => {
		try {
			if (debouncedSearch) {
				const resultSearch = await getListMenu({ Keyword: debouncedSearch.toLowerCase() });
				setDataSearch(resultSearch);
			} else {
				setDataSearch(listMenu);
			}
		} catch (error) {}
	};

	useEffect(() => {
		if (userInfo) getMenu();
	}, [userInfo]);

	useEffect(() => {
		getDataSearch();
	}, [debouncedSearch]);

	useEffect(() => {
		if (!isMobile) {
			setListTab([...listTab, ...listCategory]);
		}
	}, [isMobile]);

	useEffect(() => {
		const newListAllMenu = listTabInnit.map((item) => {
			return {
				...item,
				list: item.id === 3 ? listMenu : item.id === 2 ? listFavoriteMenu : listRecentMenu,
			};
		});
		setListTab(newListAllMenu);

		const listMenuCategory = _.chain(listMenu)
			.groupBy('parentName')
			.map((value, key) => ({ name: key, list: value }))
			.value()
			.map((item, index) => ({ ...item, id: index + 4 }));
		setListCategory(listMenuCategory);

		if (isMobile) {
			setListTab(newListAllMenu);
		} else {
			setListTab([...newListAllMenu, ...listMenuCategory]);
		}
	}, [listMenu, listRecentMenu]);

	if ((isShowSearch && isMobile) || inputSearch) {
		return (
			<div className={`header-main-menu ${isShowSearch ? 'active' : ''}`}>
				<div className="menu">
					<div className="search-mb">
						<Input
							style={{ marginLeft: 15 }}
							className="input-search "
							size="large"
							placeholder="Tìm kiếm tính năng"
							prefix={<SearchOutlined />}
							onChange={(e) => onChange(e)}
							allowClear
							value={inputSearch}
						/>
						<div className="search-mb__close" onClick={() => closeMenu()}>
							Thoát
						</div>
					</div>
					<div className="menu-result">
						{dataSearch.length > 0 ? (
							dataSearch.map((item, index) => {
								return (
									<Fragment key={index}>
										<MenuItem
											item={item}
											onUpdateFavorite={handleOnUpdateFavorite}
										/>
									</Fragment>
								);
							})
						) : (
							<div className="no-data">Không có dữ liệu</div>
						)}
					</div>
				</div>
			</div>
		);
	}
	return (
		<div className={`header-main-menu ${isShow ? 'active' : ''}`} ref={refMenu}>
			<div className="menu">
				<Tabs
					tabPosition={isMobile ? 'top' : 'left'}
					onChange={onChangeTab}
					activeKey={active}
				>
					{listTab.map((tab) => {
						return (
							<TabPane tab={<Tab item={tab} keyActive={active} />} key={tab.id}>
								{tab.list.length > 0 ? (
									tab.list.map((item, index) => {
										return (
											<Fragment key={index}>
												<MenuItem
													item={item}
													onUpdateFavorite={handleOnUpdateFavorite}
												/>
											</Fragment>
										);
									})
								) : (
									<div className="no-data">Không có dữ liệu</div>
								)}
							</TabPane>
						);
					})}
				</Tabs>
			</div>
		</div>
	);
};

export default MenuDashboard;
