import { createSlice } from '@reduxjs/toolkit';

type initialStateMenuType = {
	listMenu: MenuType[];
	listFavoriteMenu: MenuType[];
	listRecentMenu: MenuType[];
	showScreenNotice: boolean;
};

const initialState: initialStateMenuType = {
	listMenu: [],
	listFavoriteMenu: [],
	listRecentMenu: [],
	showScreenNotice: false,
};

const commonSlice = createSlice({
	name: 'menu',
	initialState,
	reducers: {
		setListMenu: (state, action) => {
			state.listMenu = action.payload;
			state.listFavoriteMenu = action.payload.filter((item) => item.isFavourite);
		},
		setListMenuRecent: (state, action) => {
			if (action.payload.length > 0) {
				const newListRecent = action.payload.filter((item, index) => index < 12);
				state.listRecentMenu = newListRecent;
			}
		},
		setIsShowScreenNotice: (state, action) => {
			state.showScreenNotice = action.payload;
		},
	},
});
const { actions, reducer } = commonSlice;
export const { setListMenu, setListMenuRecent, setIsShowScreenNotice } = actions;
export default reducer;
