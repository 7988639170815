export function getDomainByHost() {
  let host = 'erp.30shine.com';
  if(!!window && window.location && window.location.host) {
    host = window.location.host
  }
  // Xử lý trường hợp localhost
  if (host.startsWith("localhost")) {
    return "localhost";
  }

  return host.split('.').slice(-2).join('.');
}

export function convertLink(inputLink: string) {
  const currentDomain = getDomainByHost();
  let link = inputLink
  if(!link.startsWith('localhost')) {
    link = inputLink.replace('30shine.com', currentDomain).replace('30shine.org', currentDomain);
  }

  return link
}