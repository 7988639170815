import React, {useEffect, useState} from 'react';
import "./App.scss";
import request from "utils/RequestManager/RequestGlobalConfig";
import config from "configs/env";
import MainDashboard from "./pages";
import { SSO } from "@30shine-fe/sso-erp";

const sso = new SSO(process.env.REACT_APP_ENV || "test");

function App() {
    const [isAuthenticated, setIsAuthenticated] = useState(false);

    const fetchCredentials = async () => {
        try {
            const credentials = await sso.ssoLogin();
            if (credentials) {
                // @ts-ignore
                request.defaults.headers.common["Authorization"] = "Bearer " + credentials.AccessToken;
                request.interceptors.response.use( response => {
                    return response;
                }, async error => {
                    console.log(error);
                    const originalRequest = error.config;
                    if (error.response.status === 401) {
                        let data = await sso.refreshToken();
                        originalRequest.headers = {
                            'Authorization': 'Bearer ' + data.AccessToken
                        };
                        request.defaults.headers.common["Authorization"] = "Bearer " + data.AccessToken;
                        return request(originalRequest);
                    }
                    
                    return Promise.reject(error);
                });

                setIsAuthenticated(true);
            }
        } catch (e) {
            console.log(e)
        }
    };

    useEffect(() => {
        fetchCredentials();
    }, []);

    return (
      <div className="App">
          { isAuthenticated && <MainDashboard /> }
      </div>
    );
}

export default App;
