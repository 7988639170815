
import { useEffect, useState } from 'react';
import { Route } from 'react-router-dom'
import { useHistory, useLocation } from "react-router-dom";
import { Mocks } from 'utils';
import { orEmpty } from 'utils/Selector';

function PrivateRoute({ component: Component, user, ...others }) {
    const history = useHistory()
    const location = useLocation();


    async function getListMenus() {
        try {
            // handle Roles
            // const menus = await Mocks.MENUS.getRoleMenus(orEmpty("role", user))
            // if (menus.length > 0) {
            //     const result = menus.find((item) => item.key === "/" + location.pathname.split("/")[1])
            //     if (result) return ;
            //     history.push("/403")
            // }
        } catch {
            console.log("error")
        }
    }

    useEffect(() => {
        getListMenus()
    }, [user])
    return (
        <Route
            {...others}
            render={props => {
                return (
                    <Component {...others} {...props} />
                )
            }}
        />
    );
}
export default PrivateRoute