import {
  LineChartOutlined,
  TagOutlined,
  RocketOutlined,
  TeamOutlined,
  BookOutlined,
  ContactsOutlined,
  DollarCircleOutlined,
} from "@ant-design/icons";

export const menus = [
  {
    isParent: true,
    key: "/",
    path: "/",
    title: "Tổng quan",
    icon: <LineChartOutlined />,
    nested: []
  }
];
