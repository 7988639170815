import React, { useMemo } from "react";
import { Result, Button } from "antd";
import { Link } from "react-router-dom";
import { withReducer } from "hoc";
import { orEmpty } from "utils/Selector";

function Page403(props) {
    const { state } = props
  
    const isCTV = useMemo(() => {
        return orEmpty("userReducer.user.role", state) === "COLLABORATOR"
      }, [state.userReducer])
      
    return (
        <Result
            status="403"
            title="403"
            subTitle="Xin lỗi, bạn không có quyền truy cập trang này."
            extra={<Button type="primary"><Link to={isCTV ? "/blogs" : "/"} >Quay lại trang chính</Link></Button>}
        />
    )
}

export default withReducer({
    key: "page403"
})(Page403);