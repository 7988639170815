import request from "../RequestGlobalConfig";

const Erp = {
  withAuthorize: {
    get: function( path, params = {}) {
      return request({
        method: "GET",
        url: process.env.REACT_APP_API_PUSH_NOTICE_STAFF + path,
        params: params,
        headers: {
          "Content-type": "application/json"
        }
      });
    },
  },
};

export default Erp;
