import { RequestManager } from "utils";

const getUser = (params?: any) => {
    return RequestManager.author.withAuthorize.get(
        "/api/user",
        params,
    );
};

export default {
    getUser
};
