import { Divider } from 'antd';
import React from 'react';

const Tab = ({ item, keyActive }) => {
	return (
		<>
			<div className="tab__item">
				{item.icon && (
					<div className="tab__img">
						<img src={keyActive == item.id ? item.iconActive : item.icon} alt="" />
					</div>
				)}
				<div className="tab__name">{item.name}</div>
			</div>
			{item.id === 3 && <Divider />}
		</>
	);
};

export default Tab;
