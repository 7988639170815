import React from 'react';
import store from 'redux/store';
import { Provider } from 'react-redux';
export const StoreContext = React.createContext(null);

const Store = ({ children }) => {
	const [sharing, setSharing] = React.useState({});
	const [help, setHelp] = React.useState({});

	const onSetSharing = (value) => {
		setSharing((state) => Object.assign(state, value));
	};

	const onSetHelp = (value) => {
		setHelp((state) => Object.assign(state, value));
	};

	const storeContext = {
		sharing,
		help,
		setSharing: onSetSharing,
		setHelp: onSetHelp,
	} as any;

	return (
		<StoreContext.Provider value={storeContext}>
			<Provider store={store}>{children}</Provider>
		</StoreContext.Provider>
	);
};

export default Store;
