import React, { createRef, useCallback, useEffect, useState } from 'react';
import { Layout, Dropdown, Button, Menu, Input } from 'antd';
import { CaretDownOutlined, LogoutOutlined, SearchOutlined } from '@ant-design/icons';
import './DashboardLayout.scss';
import MainRouter from '../routes/MainRouter';
import { BrowserRouter as Router } from 'react-router-dom';
// import logo from '../assets/images/logo.png';
import menuIcon from '../assets/images/menu.png';
import menuIconActive from '../assets/images/menu_active.png';
import personIcon from '../assets/images/person.png';
import searchIcon from '../assets/images/ic_search.png';
import searchActiveIcon from '../assets/images/ic_search_active.png';
import noticeIcon from '../assets/images/ic_noti_menu.png';
import MenuDashboard from '../components/DashboardLayout/MenuDashboard';
import useOutside from 'hook/useOutside';
import { AppDispatch, useAppSelector } from 'redux/store';
import { useDispatch } from 'react-redux';
import { fetchUser } from './userSlice';
import { SSO } from '@30shine-fe/sso-erp';
import { setIsShowScreenNotice } from 'redux/slices/menuSlice';
import PushNotice from 'pages/DefaultDashboard/PushNotice';

const sso = new SSO(process.env.REACT_APP_ENV || 'test');
const { Header, Content } = Layout;

function DashboardLayout() {
	const [user, setUser] = useState(null);
	const [search, setSearch] = useState('');
	const [isShowSearch, setIsShowSearch] = useState(false);
	const [isShowMenu, setIsShowMenu] = useState(false);
	const refListMenu = createRef<HTMLDivElement>();
	const refButtonMenu = createRef<HTMLDivElement>();
	const refButtonSearch = createRef<HTMLDivElement>();
	const { userInfo } = useAppSelector((state) => state.user);
	const { showScreenNotice } = useAppSelector((state) => state.menu);
	const dispatch = useDispatch<AppDispatch>();

	const logOut = async () => {
		sso.ssoLogout();
	};

	const changePassWord = () => {
		sso.redirectToDomainTarget(`/update-password`);
	};

	const menu = (
		<Menu className="dropdown-account">
			<Menu.Item key="1" onClick={changePassWord}>
				Đổi mật khẩu
			</Menu.Item>
			<Menu.Item key="2" onClick={logOut}>
				Đăng xuất
			</Menu.Item>
		</Menu>
	);

	const RouterMain = useCallback(() => {
		return <MainRouter user={user} />;
	}, [user]);

	const onCloseMenu = () => {
		if (isShowSearch) {
			setIsShowSearch(false);
		}
		if (isShowMenu) {
			setIsShowMenu(false);
		}
	};

	const onChangeSearch = (e) => {
		setSearch(e.target.value);
		if (e.target.value) {
			if (!isShowSearch) setIsShowSearch(true);
		}
	};

	useOutside(refListMenu, refButtonSearch, () => {
		if (isShowSearch) {
			setIsShowSearch(false);
			setSearch('');
		}
	});

	useOutside(refListMenu, refButtonMenu, () => {
		if (isShowMenu) {
			setIsShowMenu(false);
			setSearch('');
		}
	});

	useEffect(() => {
		dispatch(fetchUser());
	}, []);

	useEffect(() => {
		if (userInfo) setUser(userInfo);
	}, [userInfo]);

	useEffect(() => {
		const body = document.querySelector('body');
		if (isShowMenu || isShowSearch) {
			body.style.margin = '0';
			body.style.height = '100%';
			body.style.overflow = 'hidden';
		} else {
			body.style.removeProperty('margin');
			body.style.removeProperty('height');
			body.style.removeProperty('overflow');
		}
	}, [isShowMenu, isShowSearch]);

	return (
		<Router>
			<Layout id={'erp-v2'}>
				<Layout className={'site-layout-true'}>
					<Header className="header">
						<div
							className="container"
							style={{
								display: 'flex',
								alignItems: 'center',
								position: 'relative',
							}}
						>
							<div className="header-left">
								<div className="logo">
									<a href="/">
										<b style={{ fontSize: '20px', textTransform: 'uppercase', color: '#fff', cursor: 'pointer' }}>
											ERP
										</b>
										{/* <img src={logo} alt="" /> */}

									</a>
								</div>
								<div className="divider"></div>
								<div
									className={`header-menu ${
										isShowMenu ? 'header-show-menu' : ''
									}`}
									onClick={() => {
										setIsShowMenu(!isShowMenu);
										setIsShowSearch(false);
									}}
									ref={refButtonMenu}
								>
									<div>
										<img src={isShowMenu ? menuIconActive : menuIcon} alt="" />
									</div>
									<span
										style={{
											fontWeight: 'bold',
											color: isShowMenu ? '#766CD6' : '#fff',
											fontSize: 18,
											marginLeft: 15,
										}}
									>
										Menu
									</span>
								</div>
							</div>
							<div
								className={isShowSearch ? 'search header-show-menu' : 'search'}
								ref={refButtonSearch}
							>
								<Input
									style={{ marginLeft: 15 }}
									className="input-search "
									size="large"
									placeholder="Tìm kiếm tính năng"
									prefix={
										<SearchOutlined
											style={{ color: isShowSearch ? '#766cd6' : '#D1D1D1' }}
											onClick={() => setIsShowSearch(!isShowSearch)}
										/>
									}
									onClick={() => setIsShowSearch(true)}
									onChange={(e) => onChangeSearch(e)}
									value={search}
								/>
								<div
									className="input-search-mb"
									onClick={() => setIsShowSearch(!isShowSearch)}
								>
									<img
										src={isShowSearch ? searchActiveIcon : searchIcon}
										alt=""
										className="img-search"
									/>
								</div>
							</div>
							<div
								className="notice"
								onClick={() => {
									dispatch(setIsShowScreenNotice(!showScreenNotice));
								}}
							>
								<img src={noticeIcon} alt="" />
							</div>
							<Dropdown overlay={menu} className="account-dropdown">
								<Button className="account-button">
									<img src={personIcon} alt="" />
									<strong style={{ marginLeft: 10 }}>
										<span className="account-button__prevText"> Xin chào,</span>
										{user?.fullName}
									</strong>
									<CaretDownOutlined color="white" />
								</Button>
							</Dropdown>

							<div ref={refListMenu}>
								<MenuDashboard
									isShow={isShowMenu || isShowSearch}
									isShowMenu={isShowMenu}
									isShowSearch={isShowSearch}
									inputSearch={search}
									onChange={(e) => onChangeSearch(e)}
									closeMenu={() => onCloseMenu()}
								/>
							</div>
						</div>
					</Header>
					<Content className="site-layout-background content">
						<RouterMain />
					</Content>
				</Layout>
			</Layout>
		</Router>
	);
}

export default DashboardLayout;
