import React, { Suspense } from 'react';
import { Switch, Route } from 'react-router-dom';
import { withRouter } from 'react-router';
import Loading from '../components/DashboardLayout/Loading';
import { PrivateRoute } from 'components';
import Page404 from '../pages/Errors/404';
import Page403 from '../pages/Errors/403';
import Page500 from '../pages/Errors/500';

const DefaultDashboard = React.lazy(() => import('../pages/DefaultDashboard'));

function MainRouter(props) {
	return (
		<div>
			<Suspense fallback={<Loading />}>
				<Switch>
					<PrivateRoute exact path={'/'} component={DefaultDashboard} {...props} />
					<PrivateRoute path="/403" component={Page403} {...props} />
					<PrivateRoute path="/500" component={Page500} {...props} />

					<PrivateRoute path="*" component={Page404} {...props} />
				</Switch>
			</Suspense>
		</div>
	);
}

export default withRouter(MainRouter);
