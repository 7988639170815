import { Users as UsersAPI } from 'api';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

export const fetchUser = createAsyncThunk('user/fetchUser', async () => {
	const response = await UsersAPI.getUser();
	const { data, status } = response;
	if (status === 200) {
		return data;
	}
	return undefined;
});

const innitState = {
	userInfo: undefined,
};

const userSlice = createSlice({
	name: 'user',
	initialState: innitState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(fetchUser.fulfilled, (state, action) => {
			state.userInfo = action.payload;
		});
	},
});

const { reducer: userReducer } = userSlice;
export default userReducer;
