import React, { useReducer, useEffect } from "react";
import logger from "use-reducer-logger";
import { notification, Spin } from "antd";
import { orNull, orEmpty, orNumber } from "utils/Selector";

const dspComplier = {};
const actCompiler = {};
const stCompiler = {};

const withReducer = props => Component => {
  const { action, reducer, initialState, key } = props;

  function HookWrapper(childProps) {
    const [state, dispatch] = useReducer(
      process.env.NODE_ENV === "development" ? logger(reducer) : reducer,
      initialState
    );

    Object.assign(actCompiler, { [key]: action });
    Object.assign(stCompiler, { [key]: state });
    Object.assign(dspComplier, { [key]: dispatch });


    const OpenNotification = () => {
      if (orNull("message", state) && orNull("type", state)) {
        notification[orEmpty("type", state)]({
          message: orEmpty("message", state)
        });
      }
    };

    useEffect(() => {
      OpenNotification();
    }, [state]);

    return (
      <Spin spinning={orNumber("counter", state) > 0 ? true : false}>
        <Component
          {...props}
          {...childProps}
          dispatch={dspComplier}
          action={actCompiler}
          state={stCompiler}
        />
      </Spin>
    );
  }

  function Wrapper(props) {
    return <HookWrapper {...props} />;
  }

  return Wrapper;
};

export default withReducer;
