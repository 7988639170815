import React, { useEffect, useState } from 'react';
import { StarOutlined, StarFilled } from '@ant-design/icons';
import { Link, useHistory } from 'react-router-dom';
import { Menu } from 'api';
import { useDispatch } from 'react-redux';
import { AppDispatch, useAppSelector } from 'redux/store';
import { setListMenuRecent } from 'redux/slices/menuSlice';
import { notification } from 'antd';

const MenuItem = ({ item, onUpdateFavorite }) => {
	const [itemMenu, setItemMenu] = useState(null);
	const dispatch = useDispatch<AppDispatch>();
	const { listRecentMenu } = useAppSelector((state) => state.menu);
	const history = useHistory();

	const handleOnClickOpenLink = async (e) => {
		e.preventDefault();
		window.open(itemMenu.link);
		try {
			const response: any = await Menu.updateRecent(itemMenu);
			if (response?.data?.errorCode === 0) {
				let newListRecentMenu = listRecentMenu;
				if (listRecentMenu.length > 0) {
					newListRecentMenu = listRecentMenu.filter(
						(menu) => menu.pageId !== itemMenu.pageId
					);
				}
				dispatch(setListMenuRecent([itemMenu, ...newListRecentMenu]));
			}
		} catch (error) {
		} 
		
	};

	useEffect(() => {
		if (item) {
			setItemMenu(item)
		};
	}, [item]);

	return itemMenu && (<div className="menu__item">
			<div className="item__img" onClick={() => onUpdateFavorite(itemMenu)}>
				{itemMenu.isFavourite ? (
					<StarFilled style={{ fontSize: 18, color: '#FFCC33' }} />
				) : (
					<StarOutlined style={{ fontSize: 18, color: '#BABABA' }} />
				)}
			</div>
			<a
				href={itemMenu.link || ''}
				onClick={(e) => handleOnClickOpenLink(e)}
			>
				<div className="item__content">
					<div className="content__top">
						{itemMenu?.parentName}/ {itemMenu?.name}
					</div>
					<div className="content__bottom">{itemMenu?.description}</div>
				</div>
			</a>
		</div>)
};

export default MenuItem;
