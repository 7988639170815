import { RequestManager } from 'utils';
import { convertLink } from 'utils/Helpers/url';

function convertDataLink(menus: any) {
	let dataConverted = []
	if(menus.length > 0) {
		dataConverted = menus.map(el => {
			return {
				...el,
				link: convertLink(el.link)
			}
		})
	}

	return dataConverted
}

const getListMenu = async (params?: any) => {
	const rs = await RequestManager.erp.withAuthorize.get('/api/menus', params);
	rs.data.data = convertDataLink(rs?.data?.data || []);
	
	return rs
};

const getListLink = async (params?: any) => {
	const rs = await RequestManager.erp.withAuthorize.get('/api/menus/flexible-link', params);
	rs.data.data = convertDataLink(rs?.data?.data || []);

	return rs
};

const updateFavorite = (params?) => {
	return RequestManager.erp.withAuthorize.post('/api/menus/favourite', params);
};
const updateRecent = (params?) => {
	return RequestManager.erp.withAuthorize.post('/api/menus/recent', params);
};

export default {
	getListMenu,
	getListLink,
	updateRecent,
	updateFavorite,
};
